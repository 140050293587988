import { ReactElement } from 'react';
import { css, cx } from '@emotion/css';

//#region styles
const paddingX = '0px';
const paddingY = '0px';
const numberOfColumns = 12;
const styles = css`
  display: grid;
  padding: ${paddingY} ${paddingX};
  height: calc(100% - ${paddingY} * 2);
  width: calc(100% - ${paddingX} * 2);
  grid-template-columns: repeat(
    ${numberOfColumns},
    calc(100% / ${numberOfColumns})
  );
`;
//#endregion

interface IMainGrid {
  children: ReactElement | ReactElement[];
  className?: string;
}

export const MainGrid: React.FC<IMainGrid> = ({ children, className }) => (
  <div className={cx(styles, className)}>{children}</div>
);
