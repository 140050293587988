import { TextField } from '@mui/material';
import {
  SubTitle,
  SubTitle2,
  SubTitle3,
} from 'general/components/Titles/Titles';
import { ContactoSchema, TContacto } from 'general/schemas/ContactoSchema';
import { ReactElement, useEffect, useRef, useState } from 'react';
import { debounceTime, raceWith, repeat, Subject, take } from 'rxjs';
import { useFactory } from 'services/useFactory';
import { useObservable } from 'services/useObservable';
import { z } from 'zod';
import { pipe } from 'fp-ts/function';
import { match } from 'ts-pattern';
import { locationsEnum, TLocations } from 'App';
import MaskTextField from 'general/components/MaskTextField';
import useLocale from 'services/useLocation';

type TErrors = z.ZodFormattedError<TContacto> | null;

export const initialStateTransportista: TContacto = {
  nombre: '',
  email: '',
  telefono: '',
  rfc: '',
  rut: '',
};

const validateForm = (
  form: TContacto,
  setErrors: (errors: TErrors) => void,
  setValidForm: (valid: boolean) => void
): void => {
  const result = ContactoSchema.safeParse(form);
  if (!result.success) {
    // set errors
    setErrors(result.error.format());
    // set page invalid
    setValidForm(false);
  } else {
    // clear errors
    setErrors(null);
    // set page valid
    setValidForm(true);
  }
};

interface IDatosTransportitsta {
  handleValidStep: (valid: boolean) => void;
  handleFormValuePage: (newFormValues: TContacto) => void;
  getInitialState: () => TContacto;
}

const DatosTransportista: React.FC<IDatosTransportitsta> = ({
  handleValidStep,
  handleFormValuePage,
  getInitialState,
}) => {
  const locale = useLocale();

  const [formValues, setFormValues] = useState(getInitialState());

  const [errors, setErrors] = useState<TErrors>(null);

  const form$ = useFactory(() => new Subject<TContacto>());
  // para sincronizar los cambios cuando se abandona el componente sin esperar el delay
  const instantForm$ = useFactory(() => new Subject<TContacto>());

  // para guardar el último valor entre renderizados para el unmount
  const formValuesRef = useRef(formValues);
  useEffect(() => {
    formValuesRef.current = formValues;
  }, [formValues]);

  useEffect(() => {
    // unmount y actualizamos el estado
    return () => {
      instantForm$.next(formValuesRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useObservable(
    () =>
      form$.pipe(debounceTime(400), raceWith(instantForm$), take(1), repeat()),

    {
      next: (newFormValues) => {
        validateForm(newFormValues, setErrors, handleValidStep);
        handleFormValuePage(newFormValues);
      },
    }
  );

  const handleInput =
    <T extends keyof TContacto>(
      field: T,
      ...modifiers: ((param: string) => string)[]
    ) =>
    (
      value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
    ) => {
      const inputValue = pipe(
        value,
        (stringOrEvent) => {
          if (typeof stringOrEvent === 'string') {
            return stringOrEvent;
          }
          return stringOrEvent.target.value;
        },
        ...(modifiers as [])
      );
      const newValues = { ...formValues, [field]: inputValue };
      form$.next(newValues);
      setFormValues(newValues);
    };

  return (
    <>
      <div className="textContainer">
        <SubTitle>Datos Transportista</SubTitle>
      </div>
      <div className="textContainer">
        <SubTitle2>
          Estos Datos son necesarios para vincular el viaje a una empresa
          Transportista. Es un dato clave para brindar una mejor seguridad.
        </SubTitle2>
      </div>
      <div className="textContainer">
        <SubTitle3>Transportista</SubTitle3>
      </div>
      <div className="textFieldContainer">
        <TextField
          onChange={handleInput('nombre')}
          value={formValues.nombre}
          helperText={errors?.nombre?._errors[0]}
          fullWidth
          label="Nombre"
          size="small"
        />
        {match<TLocations, ReactElement>(locale)
          .with(locationsEnum.enum.mx, () => (
            <TextField
              onChange={handleInput(
                'rfc',
                (s): string => s.toUpperCase(),
                (s): string => s.trim()
              )}
              value={formValues.rfc}
              helperText={errors?.rfc?._errors[0]}
              fullWidth
              label="RFC/CURP"
              size="small"
            />
          ))
          .with(locationsEnum.enum.cl, () => (
            <MaskTextField
              onAccept={handleInput('rut', (s): string => s.trim())}
              value={formValues.rut}
              helperText={errors?.rut?._errors[0]}
              fullWidth
              label="RUT"
              mask={[
                {
                  mask: '0.000.000-#',
                  definitions: {
                    '#': /[kK0-9]/,
                  },
                },
                {
                  mask: '00.000.000-#',
                  definitions: {
                    '#': /[kK0-9]/,
                  },
                },
              ]}
            />
          ))
          .with(locationsEnum.enum.ar, () => (
            <MaskTextField
              onAccept={handleInput('cuitCuil', (s): string => s.trim())}
              value={formValues.cuitCuil}
              helperText={errors?.cuitCuil?._errors[0]}
              fullWidth
              label="CUIT/CUIL"
              mask={'00-00000000-0'}
            />
          ))
          .exhaustive()}
        <TextField
          onChange={handleInput('telefono')}
          value={formValues.telefono}
          helperText={errors?.telefono?._errors[0]}
          fullWidth
          label="Teléfono"
          size="small"
        />
        <TextField
          onChange={handleInput('email')}
          value={formValues.email}
          helperText={errors?.email?._errors[0]}
          fullWidth
          label="Mail"
          size="small"
        />
      </div>
    </>
  );
};

export default DatosTransportista;
