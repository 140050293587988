import { css, injectGlobal } from '@emotion/css';
import { Slide } from '@mui/material';
import DatosIniciales, {
  EmpresaSchema,
  initialStateDatosIniciales,
} from 'form/pages/DatosIniciales';
import DatosTransportista, {
  initialStateTransportista,
} from 'form/pages/DatosTransportista';
import PuntoSalida, {
  initialStatePuntosSalida,
  PuntosSalidaSchema,
} from 'form/pages/PuntoSalida';
import RespSeguridad, {
  initialStateRespSeguridad,
  RespSeguridadSchema,
} from 'form/pages/RespSeguridad';
import SidePanel from 'form/SidePanel';
import { Title } from 'general/components/Titles/Titles';
import { MainGrid } from 'general/components/Layaout';
import { useCallback, useEffect, useRef, useState } from 'react';
import { match } from 'ts-pattern';
import { palette } from 'general/Styles';
import { ContactoSchema } from 'general/schemas/ContactoSchema';
import Bienvenida from 'form/Bienvenida';
import Despedida from 'form/Despedida';
import { z } from 'zod';
import toast from 'react-hot-toast';
import { pipe } from 'fp-ts/function';
import useMail from 'services/useMail';
import config from 'config';

// declare global {
//   interface Window {
//     grecaptcha: ReCaptchaInstance;
//     captchaOnLoad: () => void;
//   }
// }
//
// interface ReCaptchaInstance {
//   ready: (cb: () => any) => void;
//   execute: (options: ReCaptchaExecuteOptions) => Promise<string>;
//   render: (id: string, options: ReCaptchaRenderOptions) => any;
// }
//
// interface ReCaptchaExecuteOptions {
//   action: string;
// }
//
// interface ReCaptchaRenderOptions {
//   sitekey: string;
//   size: 'invisible';
// }

injectGlobal`

  body {
    margin: 0;
  }

  .MuiTextField-root label{
    color: ${palette.colcom.primary.light} !important;
  }

  .MuiFormHelperText-root.Mui-error, .MuiFormHelperText-root{
    position: absolute !important;
    top:100% !important;
    font-size:10px !important;
    margin-top:0 !important;
    color: ${palette.colcom.error.main} !important;
  }

  .Mui-focused{
    & .MuiOutlinedInput-notchedOutline{
      border-color: ${palette.colcom.primary.light} !important;
    }
  }

`;

const styles = {
  container: css`
    width: 100vw;
    height: 100vh;
    background: transparent linear-gradient(281deg, #e5e9f5 0%, #e5e9f500 100%)
      0 0 no-repeat padding-box;
  `,
  grid: css`
    grid-template-rows: 21vh auto;

    & > #sidePanel {
      grid-column: 1 / span 3;
      grid-row: 1 / -1;
    }

    & #title {
      display: grid;
      grid-column: 4 / 13;
      grid-template-columns: repeat(9, 1fr);
      align-items: center;

      & div:first-of-type {
        grid-column: 3 / -1;
      }

      & .line {
        height: 1px;
        width: 100%;
        grid-column: 1 / -1;
        background-color: ${palette.colcom.primary.light};
      }
    }

    & > #pages {
      grid-column: 4 / 13;
      display: grid;
      grid-template-columns: repeat(9, 1fr);
      overflow: hidden;

      & > div {
        width: 100%;
        height: 100%;
        display: grid;
        grid-column: 1/-1;
        grid-template-columns: inherit;
        grid-auto-rows: minmax(min-content, max-content);
        align-items: center;

        & > * {
          grid-column: 3 / span 5;
        }

        & .textContainer {
          display: flex;
          align-items: center;
        }

        & .textFieldContainer {
          display: grid;
          width: 100%;
          column-gap: 50px;
          grid-template-rows: 6.48vh 6.48vh;
          grid-template-columns: 1fr 1fr;
          row-gap: 10px;
          align-items: center;
        }
      }
    }

    & > .mensaje {
      grid-column: 4 / 13;
      grid-row: 1 / -1;
    }
  `,

  toast: {
    span: css`
      font-family: Roboto, Medium, serif;
    `,
    fakeLink: css`
      cursor: pointer;
      color: ${palette.colcom.primary.medium};
      text-decoration: underline;
    `,
  },
};

//#region pages form values
const enumPhases = {
  start: 0,
  form: 1,
  finish: 2,
} as const;

type enumPhasesValues = typeof enumPhases[keyof typeof enumPhases];

const FormSchema = z.object({
  datosIniciales: EmpresaSchema,
  puntosSalidas: PuntosSalidaSchema,
  respSeguridad: RespSeguridadSchema,
  transportista: ContactoSchema,
});

const FormSchemaKeys = FormSchema.keyof();

type TFormSchemaKeys = z.infer<typeof FormSchemaKeys>;

export const enumStepPages:
  | {
      // enum follows form schema
      [k in TFormSchemaKeys]: 0 | 1 | 2 | 3;
    }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any = {
  datosIniciales: 0,
  puntosSalidas: 1,
  respSeguridad: 2,
  transportista: 3,
} as const;

export type enumStepPagesValues =
  typeof enumStepPages[keyof typeof enumStepPages];

// keyof steps
export type TValidSteps = {
  [k in keyof typeof enumStepPages]: boolean;
};

const initialValidSteps: TValidSteps = {
  datosIniciales: false,
  puntosSalidas: true,
  respSeguridad: true,
  transportista: true,
};

export type TForm = z.infer<typeof FormSchema>;

const initialState: TForm = {
  datosIniciales: initialStateDatosIniciales,
  puntosSalidas: initialStatePuntosSalida,
  respSeguridad: initialStateRespSeguridad,
  transportista: initialStateTransportista,
};

const validateForm = (validSteps: TValidSteps): enumStepPagesValues | null => {
  const step = pipe(
    validSteps,
    Object.entries,
    (arr) => arr.find(([_, value]) => value === false)?.[0]
  );

  if (step === undefined) return null;

  return enumStepPages[step as keyof typeof enumStepPages];
};

//#endregion

const Form: React.FC = () => {
  const [activePhase, setActivePhase] = useState<enumPhasesValues>(
    enumPhases.start
  );
  const [activeStep, setActiveStep] = useState<enumStepPagesValues>(
    enumStepPages.datosIniciales
  );
  const [validSteps, setValidSteps] = useState(initialValidSteps);

  const [formValues, setFormValues] = useState(initialState);

  const [slideDirection, setSlideDirection] = useState<'left' | 'right'>(
    'right'
  );

  const handleFormValuePage =
    <T extends keyof TForm>(field: T) =>
    (newValue: TForm[typeof field]) =>
      setFormValues((prev) => ({ ...prev, [field]: newValue }));

  const getFormValues =
    <T extends keyof TForm>(field: T) =>
    () =>
      formValues[field];

  const handleNextPage = useCallback(() => {
    setSlideDirection('left');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setActiveStep((prev: any) => (prev + 1) as enumStepPagesValues);
  }, []);

  const handlePrevPage = useCallback(() => {
    setSlideDirection('right');
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setActiveStep((prev: any) => (prev - 1) as enumStepPagesValues);
  }, []);

  const handleGoToPage = useCallback(
    (pageStep: enumStepPagesValues) => {
      if (activePhase !== enumPhases.form) return;
      const slideDirection = activeStep < pageStep ? 'left' : 'right';
      setSlideDirection(slideDirection);
      setActiveStep(pageStep);
    },
    [activePhase, activeStep]
  );

  const handleValidStep =
    <T extends keyof TValidSteps>(step: T) =>
    (valid: boolean) =>
      setValidSteps((prev) => ({ ...prev, [step]: valid }));

  const pagesContainerRef = useRef(null);

  const onError = (): void => {
    setActiveStep(enumStepPages.datosIniciales);
    setActivePhase(enumPhases.form);
    toast(
      <span className={styles.toast.span}>
        Ocurrió un error por favor reintentalo
      </span>,
      {
        style: {
          color: 'white',
          backgroundColor: palette.colcom.error.main,
        },
      }
    );
  };

  const [loading, setLoading] = useState(false);

  const { loading: loadingEmail, sendSource$ } = useMail(onError);

  const handleUpload = (formValues: TForm, token: string): void => {
    setActivePhase(enumPhases.finish);
    sendSource$.next({ form: formValues, token: token });
  };
  const handleCaptcha = (): void => {
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha
        .execute(config.CAPTCHA_KEY, { action: 'homepage' })
        .then((token) => {
          setLoading(false);
          if (token === null) return;
          handleUpload(formValues, token);
        });
    });
  };

  const handleFinalizar = useCallback(() => {
    //validate
    const firstStepWithError = validateForm(validSteps);
    if (firstStepWithError !== null) {
      // show toast error
      toast((t) => (
        <span className={styles.toast.span}>
          Por favor revise:{' '}
          <span
            onClick={(): void => {
              toast.dismiss(t.id);
              setActiveStep(firstStepWithError);
            }}
            className={styles.toast.fakeLink}
          >
            {match(firstStepWithError)
              .with(0, () => 'Datos Iniciales')
              .with(1, () => 'Puntos de Salida/Carga/Entrega')
              .with(2, () => 'Responsable de seguridad viaje')
              .with(3, () => 'Datos Transportista')
              .exhaustive()}
          </span>
        </span>
      ));

      return;
    }

    // generamos el token con el captcha
    handleCaptcha();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  useEffect(() => {
    const loadScriptByURL = (
      id: string,
      url: string,
      callback: () => void
    ): void => {
      const isScriptExist = document.getElementById(id);

      if (!isScriptExist) {
        const script = document.createElement('script');
        script.type = 'text/javascript';
        script.src = url;
        script.id = id;
        script.onload = (): void => {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }

      if (isScriptExist && callback) callback();
    };

    // load the script by passing the URL
    loadScriptByURL(
      'recaptcha-key',
      `https://www.google.com/recaptcha/api.js?render=${config.CAPTCHA_KEY}`,
      () => null
    );
  }, []);

  const handleComenzar = (): void => setActivePhase(enumPhases.form);

  return (
    <div className={styles.container}>
      <MainGrid className={styles.grid}>
        <SidePanel
          validSteps={validSteps}
          activeStep={activeStep}
          handleNext={handleNextPage}
          handlePrev={handlePrevPage}
          handleFinalizar={handleFinalizar}
          disableNavigation={activePhase !== enumPhases.form}
          handleGoToPage={handleGoToPage}
        />

        {match(activePhase)
          .with(enumPhases.start, () => (
            <Bienvenida handleComenzar={handleComenzar} />
          ))
          .with(enumPhases.form, () => (
            <>
              <div id="title">
                <Title>Solicitud Demo</Title>
                <div className="line" />
              </div>
              <div id="pages" ref={pagesContainerRef}>
                <Slide
                  container={pagesContainerRef.current}
                  in
                  key={activeStep}
                  direction={slideDirection}
                  timeout={400}
                >
                  <div>
                    {match(activeStep)
                      .with(enumStepPages.datosIniciales, () => (
                        <DatosIniciales
                          handleFormValuePage={handleFormValuePage(
                            'datosIniciales'
                          )}
                          handleValidStep={handleValidStep('datosIniciales')}
                          getInitialState={getFormValues('datosIniciales')}
                        />
                      ))
                      .with(enumStepPages.puntosSalidas, () => (
                        <PuntoSalida
                          handleFormValuePage={handleFormValuePage(
                            'puntosSalidas'
                          )}
                          getInitialState={getFormValues('puntosSalidas')}
                          handleValidStep={handleValidStep('puntosSalidas')}
                        />
                      ))
                      .with(enumStepPages.respSeguridad, () => (
                        <RespSeguridad
                          handleFormValuePage={handleFormValuePage(
                            'respSeguridad'
                          )}
                          getInitialState={getFormValues('respSeguridad')}
                          handleValidStep={handleValidStep('respSeguridad')}
                        />
                      ))
                      .with(enumStepPages.transportista, () => (
                        <DatosTransportista
                          handleFormValuePage={handleFormValuePage(
                            'transportista'
                          )}
                          handleValidStep={handleValidStep('transportista')}
                          getInitialState={getFormValues('transportista')}
                        />
                      ))
                      .exhaustive()}
                  </div>
                </Slide>
              </div>
            </>
          ))
          .with(enumPhases.finish, () => (
            <Despedida loading={loading || loadingEmail} />
          ))
          .exhaustive()}
      </MainGrid>
    </div>
  );
};

export default Form;
