import { css, cx } from '@emotion/css';
import { CircularProgress } from '@mui/material';
import { Title } from 'general/components/Titles/Titles';
import { getThemeKey, palette } from 'general/Styles';
import { TLocations } from 'App';
import { useMemo } from 'react';
import useLocale from 'services/useLocation';

interface IStyles {
  [key: string]: string;
}

const getStyles = (locale: TLocations): IStyles => {
  const theme = getThemeKey(locale);

  return {
    container: css`
      display: grid;
      place-items: center;

      & #box {
        padding: 20px;
        height: 488px;
        width: 614px;
        display: grid;
        grid-template-rows: 123px 1px auto;
        box-shadow: 0 0 15px #00000026;
        border-radius: 15px;

        & div {
          padding: 10px;
        }

        & #loadingContainer {
          grid-column: 1 / -1;
          grid-row: 1/-1;
          display: grid;
          place-items: center;
        }
      }

      & #line {
        background-color: ${palette[theme].primary.light};
        padding: 0 !important;
      }

      & .textBox {
        display: grid;

        span {
          padding-top: 10px;
          color: ${palette[theme].primary.medium};
          /* margin: 20px 0; */
          font-size: 18px;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
      }
    `,
    title: css`
      & > span {
        color: ${palette[theme].primary.medium};
      }
    `,
  };
};

interface IDespedida {
  loading: boolean;
}

const Despedida: React.FC<IDespedida> = ({ loading }) => {
  const locale = useLocale();

  const styles = useMemo(() => getStyles(locale), [locale]);

  return (
    <div className={cx('mensaje', styles.container)}>
      <div id="box">
        {loading ? (
          <div id="loadingContainer">
            <CircularProgress />
          </div>
        ) : (
          <>
            <Title customStyles={styles.title}>Gracias!</Title>
            <div id="line" />
            <div className="textBox">
              <span>Muy pronto nos pondremos en contacto con usted.</span>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default Despedida;
