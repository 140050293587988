import { z } from 'zod';

const curpValida = (curp: string): boolean => {
  const re =
    /^([A-Z][AEIOUX][A-Z]{2}\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])[HM](?:AS|B[CS]|C[CLMSH]|D[FG]|G[TR]|HG|JC|M[CNS]|N[ETL]|OC|PL|Q[TR]|S[PLR]|T[CSL]|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z\d])(\d)$/;

  const validado = curp.match(re);

  if (!validado) {
    //¿Coincide con el formato general?
    return false;
  }

  //Validar que coincida el dígito verificador
  const digitoVerificador = (curp17: string): number => {
    //Fuente https://consultas.curp.gob.mx/CurpSP/
    const diccionario = '0123456789ABCDEFGHIJKLMNÑOPQRSTUVWXYZ';
    let lngSuma = 0.0;

    for (let i = 0; i < 17; i++) {
      lngSuma = lngSuma + diccionario.indexOf(curp17.charAt(i)) * (18 - i);
    }
    const lngDigito = 10 - (lngSuma % 10);
    if (lngDigito === 10) return 0;
    return lngDigito;
  };

  return parseInt(validado[2]) === digitoVerificador(validado[1]);

  //Validado
};

const CurpSchema = z
  .string()
  .refine((val) => curpValida(val), { message: 'CURP Inválido' });

export default CurpSchema;
