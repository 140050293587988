import { css, cx } from '@emotion/css';
import { Check } from '@mui/icons-material';
import { Button, Fade } from '@mui/material';
import React, { useMemo } from 'react';
import { ReactComponent as ColcomLogo } from 'general/img/colcomIcon.svg';
import { ReactComponent as ColsaLogo } from 'general/img/colsaIcon.svg';
import { enumStepPages, enumStepPagesValues, TValidSteps } from 'form/Form';
import { getThemeKey, palette } from 'general/Styles';
import { locationsEnum, TLocations } from 'App';
import { default as config } from 'config';
import { match } from 'ts-pattern';
import useLocale from 'services/useLocation';

interface IStyles {
  [key: string]: string;
}

const getStepStyles = (locale: TLocations): IStyles => {
  const theme = getThemeKey(locale);
  return {
    textSelected: css`
      color: ${palette[theme].sidePanel.colorSelected};
    `,
    circleSelected: css`
      background-color: ${palette[theme].sidePanel.circleSelected};
      transition: background-color 500ms linear;
      color: ${palette[theme].sidePanel.circleSelectedFont};
    `,
    label: css`
      grid-column: 1/3;
      text-align: left;
      font-size: 16px;
    `,
    circle: css`
      grid-column: 3;
      border: solid ${palette[theme].primary.light} 1px;
      border-radius: 50%;
      z-index: 1;
      box-sizing: border-box;
      background-color: ${palette[theme].sidePanel.bColor};
      display: grid;
      place-items: center;
      color: ${palette[theme].secondary.main};
      box-shadow: 0 0 0 10px ${palette[theme].sidePanel.bColor};
      justify-self: center;
      width: 2.35vw;
      aspect-ratio: 1 / 1;
      cursor: pointer;
    `,
  };
};

interface IStep {
  label: string;
  selected: boolean;
  circleText: string;
  completed: boolean;
  valid: boolean;
  handleClick: () => void;
}

const Step: React.FC<IStep> = ({
  selected,
  label,
  circleText,
  completed,
  valid,
  handleClick,
}) => {
  const locale = useLocale();

  const theme = useMemo(() => getThemeKey(locale), [locale]);

  const styles = useMemo(() => getStepStyles(locale), [locale]);

  return (
    <>
      <span
        className={cx(styles.label, selected ? styles.textSelected : undefined)}
      >
        {label}
      </span>

      <div
        className={cx(
          styles.circle,
          selected ? styles.circleSelected : undefined
        )}
        onClick={handleClick}
      >
        {completed ? (
          <Fade in timeout={500}>
            {valid ? (
              <Check htmlColor={palette[theme].sidePanel.circleDoneCheck} />
            ) : (
              <span>{circleText}</span>
            )}
          </Fade>
        ) : (
          circleText
        )}
      </div>
    </>
  );
};

const getStyles = (locale: TLocations): IStyles => {
  const theme = getThemeKey(locale);
  return {
    container: css`
      display: grid;
      height: 100%;
      width: calc(100% - 22px);
      padding-left: 22px;
      grid-template-rows: 21vh auto 16vh;
      grid-template-columns: 1.5fr 1.5fr 1fr;
      background-color: ${palette[theme].sidePanel.bColor};
      font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
        Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      color: ${palette[theme].sidePanel.color};
      box-shadow: 0 0 15px #00000080;

      .logo {
        grid-column: 1 / -1;
        display: grid;
        place-items: center;
        cursor: pointer;
      }
    `,
    customStepper: css`
      grid-row: 2;
      width: 100%;
      height: 100%;
      position: relative;
      display: grid;
      grid-column: 1 / -1;
      grid-template-columns: inherit;
      align-items: center;

      & > #stepsContainer {
        grid-column: 1 / -1;
        grid-row: 1;
        z-index: 2;
        display: grid;
        grid-template-columns: inherit;
        grid-auto-rows: 11%;
        place-items: center;
        height: 100%;
        align-content: space-around;
        justify-items: flex-end;
      }

      & > #line {
        grid-row: 1;
        grid-column: 3;
        height: 100%;
        background-image: linear-gradient(
          ${palette[theme].primary.light},
          ${palette[theme].primary.light}
        );
        background-size: 1px 100%;
        background-repeat: no-repeat;
        background-position: 50% 0;
      }
    `,

    botones: css`
      grid-column: 1 / -1;
      grid-row: 3;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      padding: 0 20px 20px;

      .MuiButton-root {
        height: 40px;
        text-transform: none;
        color: ${palette[theme].sidePanel.botonFontColor};
      }

      .MuiButton-root.Mui-disabled {
        color: ${palette[theme].sidePanel.botonFontColorDisabled};
      }
    `,
  };
};

interface ISidePanel {
  activeStep: enumStepPagesValues;
  validSteps: TValidSteps;
  handleNext: () => void;
  handlePrev: () => void;
  handleFinalizar: () => void;
  handleGoToPage: (page: enumStepPagesValues) => void;
  disableNavigation: boolean;
}

const SidePanel: React.FC<ISidePanel> = ({
  activeStep,
  handleNext,
  handlePrev,
  handleFinalizar,
  validSteps,
  disableNavigation,
  handleGoToPage,
}) => {
  const locale = useLocale();

  const styles = useMemo(() => getStyles(locale), [locale]);

  const enableNext = activeStep < enumStepPages.transportista;
  const enablePrev = activeStep > enumStepPages.datosIniciales;

  const redirectToHomepage = (): void => {
    window.open(config.HOMEPAGE[locale], '_bank');
  };

  const goToPage = (page: enumStepPagesValues) => () => {
    if (disableNavigation) return;
    handleGoToPage(page);
  };

  return (
    <div id="sidePanel" className={styles.container}>
      <div className="logo" onClick={redirectToHomepage}>
        {match(locale)
          .with(locationsEnum.enum.mx, () => <ColcomLogo />)
          .with(locationsEnum.enum.cl, () => <ColcomLogo />)
          .with(locationsEnum.enum.ar, () => <ColsaLogo />)
          .exhaustive()}
      </div>

      <div className={styles.customStepper}>
        <div id="line" />
        <div id="stepsContainer">
          <Step
            selected={activeStep === enumStepPages.datosIniciales}
            label="Datos Iniciales"
            circleText={(enumStepPages.datosIniciales + 1).toString()}
            completed={activeStep > enumStepPages.datosIniciales}
            valid={validSteps.datosIniciales}
            handleClick={goToPage(enumStepPages.datosIniciales)}
          />
          <Step
            selected={activeStep === enumStepPages.puntosSalidas}
            label="Puntos de Salida/Carga/Entrega"
            circleText={(enumStepPages.puntosSalidas + 1).toString()}
            completed={activeStep > enumStepPages.puntosSalidas}
            valid={validSteps.puntosSalidas}
            handleClick={goToPage(enumStepPages.puntosSalidas)}
          />
          <Step
            selected={activeStep === enumStepPages.respSeguridad}
            label="Responsable de seguridad viaje"
            circleText={(enumStepPages.respSeguridad + 1).toString()}
            completed={activeStep > enumStepPages.respSeguridad}
            valid={validSteps.respSeguridad}
            handleClick={goToPage(enumStepPages.respSeguridad)}
          />
          <Step
            selected={activeStep === enumStepPages.transportista}
            label="Datos Transportista"
            circleText={(enumStepPages.transportista + 1).toString()}
            completed={false}
            valid={validSteps.transportista}
            handleClick={goToPage(enumStepPages.transportista)}
          />
        </div>
      </div>

      <div className={styles.botones}>
        <Button
          onClick={handlePrev}
          disabled={!enablePrev || disableNavigation}
        >
          Volver
        </Button>

        {activeStep === 3 ? (
          <Button onClick={handleFinalizar} disabled={disableNavigation}>
            Finalizar
          </Button>
        ) : (
          <Button
            onClick={handleNext}
            disabled={!enableNext || disableNavigation}
          >
            Siguiente
          </Button>
        )}
      </div>
    </div>
  );
};

export default SidePanel;
