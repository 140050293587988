/* eslint-disable eqeqeq */
import { z } from 'zod';

// https://es.stackoverflow.com/questions/31713/c%C3%B3mo-validar-un-rfc-de-m%C3%A9xico-y-su-digito-verificador
const rfcValido = (rfc: string, aceptarGenerico = true): boolean => {
  const re =
    /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
  // eslint-disable-next-line prefer-const
  let validado = rfc.match(re);

  if (!validado) {
    //¿Coincide con el formato general del regex?
    return false;
  }

  //Separar el dígito verificador del resto del RFC
  const digitoVerificador = validado.pop();
  const rfcSinDigito = validado.slice(1).join('');
  const len = rfcSinDigito.length;
  //Obtener el dígito esperado
  const diccionario = '0123456789ABCDEFGHIJKLMN&OPQRSTUVWXYZ Ñ';
  const indice = len + 1;
  let suma, digitoEsperado;

  if (len == 12) {
    suma = 0;
  } else {
    suma = 481;
  } //Ajuste para persona moral

  for (let i = 0; i < len; i++) {
    suma += diccionario.indexOf(rfcSinDigito.charAt(i)) * (indice - i);
  }
  digitoEsperado = 11 - (suma % 11);
  if (digitoEsperado == 11) {
    digitoEsperado = 0;
  } else if (digitoEsperado == 10) {
    digitoEsperado = 'A';
  }

  //¿El dígito verificador coincide con el esperado? ¿O es un RFC Genérico (ventas a público general)?
  //
  if (
    digitoVerificador != digitoEsperado &&
    (!aceptarGenerico || rfcSinDigito + digitoVerificador != 'XAXX010101000')
  ) {
    return false;
  } else if (
    !aceptarGenerico &&
    rfcSinDigito + digitoVerificador == 'XEXX010101000'
  ) {
    return false;
  }
  return true;
};

const RfcSchema = z
  .string()
  .refine((val) => rfcValido(val), { message: 'RFC Inválido' });
// .refine((value) => validate);

export default RfcSchema;
