import CurpSchema from 'general/schemas/CurpSchema';
import RfcSchema from 'general/schemas/RfcSchema';
import RutSchema from 'general/schemas/RutSchema';
import { z } from 'zod';
import CuitCuilSchema from 'general/schemas/CuitCuilSchema';

export const ContactoSchema = z.object({
  nombre: z
    .string()
    .trim()
    .max(300, { message: 'Demasiado largo' })
    .or(z.literal(''))
    .optional(),

  email: z
    .string()
    .trim()
    .max(300, { message: 'Demasiado largo' })
    .email({ message: 'Mail inválido' })
    .or(z.literal(''))
    .optional(),

  telefono: z
    .string()
    .trim()
    .max(300, { message: 'Demasiado largo' })
    .regex(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/, {
      message: 'Inválido',
    })
    .or(z.literal(''))
    .optional(),

  rfc: RfcSchema.or(CurpSchema).or(z.literal('')).optional(),

  rut: RutSchema.or(z.literal('')).optional(),

  cuitCuil: CuitCuilSchema.or(z.literal('')).optional(),
});

export type TContacto = z.infer<typeof ContactoSchema>;
