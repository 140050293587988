import { css } from '@emotion/css';
import { LocationOnOutlined } from '@mui/icons-material';
import { TextField } from '@mui/material';
import {
  SubTitle,
  SubTitle2,
  SubTitle3,
} from 'general/components/Titles/Titles';
import IsoDateSchema from 'general/schemas/IsoDateSchema';
import { getThemeKey, palette } from 'general/Styles';
import { useEffect, useMemo, useRef, useState } from 'react';
import { debounceTime, raceWith, repeat, Subject, take } from 'rxjs';
import { useFactory } from 'services/useFactory';
import { useObservable } from 'services/useObservable';
import { z } from 'zod';
import { TLocations } from 'App';
import useLocale from 'services/useLocation';
import dayjs from 'dayjs';

interface IStyles {
  [key: string]: string;
}

const getStyles = (locale: TLocations): IStyles => {
  const theme = getThemeKey(locale);

  return {
    circleLocation: css`
      background-color: ${palette[theme].primary.medium};
      border-radius: 50%;
      display: grid;
      place-items: center;
      color: #f8d3d4;
      width: 2.35vw;
      aspect-ratio: 1 / 1;
      z-index: 1;
    `,

    linePuntoSalida: css`
      height: 100%;
      width: 100%;
      grid-column: 2 !important;
      grid-row: 4 / 9;
      background-image: linear-gradient(#acacac, #acacac);
      background-size: 1px 70%;
      background-repeat: no-repeat;
      background-position: 50% 50%;
    `,
  };
};

//#region schema
const stringNombreDireccionSchema = z
  .string()
  .trim()
  .max(400, { message: 'Demasiado largo' });

const lugarTiempoContenedor = z.object({
  nombreLugar: stringNombreDireccionSchema,
  direccionLugar: stringNombreDireccionSchema,
  fecha: IsoDateSchema.or(z.literal('')).optional(),
  hora: z.string().trim().optional(),
});

export const PuntosSalidaSchema = z.object({
  salida: lugarTiempoContenedor,
  carga: lugarTiempoContenedor,
  entrega: lugarTiempoContenedor,
});

//#endregion

type TLugarTiempoContenedor = z.infer<typeof lugarTiempoContenedor>;

type TPuntosSalida = z.infer<typeof PuntosSalidaSchema>;

type TErrors = z.ZodFormattedError<TPuntosSalida> | null;

const initialStatePunto: TLugarTiempoContenedor = {
  direccionLugar: '',
  fecha: '',
  nombreLugar: '',
  hora: '',
};

export const initialStatePuntosSalida: TPuntosSalida = {
  salida: initialStatePunto,
  carga: initialStatePunto,
  entrega: initialStatePunto,
};

const validateForm = (
  form: TPuntosSalida,
  setErrors: (errors: TErrors) => void,
  setValidForm: (valid: boolean) => void
): void => {
  const result = PuntosSalidaSchema.safeParse(form);

  if (!result.success) {
    // set errors
    setErrors(result.error.format());
    // set page invalid

    setValidForm(false);
  } else {
    // clear errors
    setErrors(null);
    // set page valid
    setValidForm(true);
  }
};

interface IPuntosSalida {
  handleValidStep: (valid: boolean) => void;
  handleFormValuePage: (newFormValues: TPuntosSalida) => void;
  getInitialState: () => TPuntosSalida;
}

const PuntoSalida: React.FC<IPuntosSalida> = ({
  handleValidStep,
  handleFormValuePage,
  getInitialState,
}) => {
  const locale = useLocale();

  const styles = useMemo(() => getStyles(locale), [locale]);

  const [formValues, setFormValues] = useState(getInitialState());

  const [errors, setErrors] = useState<TErrors>(null);

  const form$ = useFactory(() => new Subject<TPuntosSalida>());
  // para sincronizar los cambios cuando se abandona el componente sin esperar el delay
  const instantForm$ = useFactory(() => new Subject<TPuntosSalida>());

  // para guardar el último valor entre renderizados para el unmount
  const formValuesRef = useRef(formValues);
  useEffect(() => {
    formValuesRef.current = formValues;
  }, [formValues]);

  useEffect(() => {
    // unmount y actualizamos el estado
    return () => {
      instantForm$.next(formValuesRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useObservable(
    () =>
      form$.pipe(debounceTime(400), raceWith(instantForm$), take(1), repeat()),

    {
      next: (newFormValues) => {
        validateForm(newFormValues, setErrors, handleValidStep);
        handleFormValuePage(newFormValues);
      },
    }
  );

  const handleInput =
    <Ta extends keyof TPuntosSalida>(lugar: Ta) =>
    <Tb extends keyof TPuntosSalida[typeof lugar]>(field: Tb) =>
    (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      const newValues = {
        ...formValues,
        [lugar]: { ...formValues[lugar], [field]: event.target.value },
      };
      form$.next(newValues);
      setFormValues(newValues);
    };

  const minDate = dayjs(new Date()).format('YYYY-MM-DD');

  return (
    <>
      <div
        style={{
          gridRow: '4',
          gridColumn: '2',
          justifySelf: 'center',
        }}
        className={styles.circleLocation}
      >
        <LocationOnOutlined />
      </div>
      <div
        style={{
          gridRow: '6',
          gridColumn: '2',
          justifySelf: 'center',
        }}
        className={styles.circleLocation}
      >
        <LocationOnOutlined />
      </div>
      <div
        style={{
          gridRow: '8',
          gridColumn: '2',
          alignSelf: 'center',
          justifySelf: 'center',
        }}
        className={styles.circleLocation}
      >
        <LocationOnOutlined />
      </div>
      <div className={styles.linePuntoSalida} />

      <div className="textContainer">
        <SubTitle>Puntos de Salida, Carga y Entrega</SubTitle>
      </div>
      <div className="textContainer">
        <SubTitle2>
          Son los datos necesarios para vincular una empresa a nuestro sistema.
        </SubTitle2>
      </div>
      <div className="textContainer">
        <SubTitle3>Salida del Contenedor</SubTitle3>
      </div>
      <div className="textFieldContainer">
        <TextField
          onChange={handleInput('salida')('nombreLugar')}
          value={formValues.salida.nombreLugar}
          helperText={errors?.salida?.nombreLugar?._errors[0]}
          fullWidth
          label="Nombre Patio Contenedor"
          size="small"
        />
        <TextField
          onChange={handleInput('salida')('direccionLugar')}
          value={formValues.salida.direccionLugar}
          helperText={errors?.salida?.direccionLugar?._errors[0]}
          fullWidth
          label="Dirección"
          size="small"
        />
        <TextField
          onChange={handleInput('salida')('fecha')}
          value={formValues.salida.fecha}
          helperText={errors?.salida?.fecha?._errors[0]}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputProps: {
              min: minDate,
            },
          }}
          type={'date'}
          label="Fecha Salida"
          size="small"
        />
        <TextField
          onChange={handleInput('salida')('hora')}
          value={formValues.salida.hora}
          helperText={errors?.salida?.hora?._errors[0]}
          fullWidth
          InputLabelProps={{ shrink: true }}
          type={'time'}
          label="Hora Salida"
          size="small"
        />
      </div>
      <div className="textContainer">
        <SubTitle3>Carga del Contenedor</SubTitle3>
      </div>
      <div className="textFieldContainer">
        <TextField
          onChange={handleInput('carga')('nombreLugar')}
          value={formValues.carga.nombreLugar}
          helperText={errors?.carga?.nombreLugar?._errors[0]}
          fullWidth
          label="Nombre Planta Exportadora"
          size="small"
        />
        <TextField
          onChange={handleInput('carga')('direccionLugar')}
          value={formValues.carga.direccionLugar}
          helperText={errors?.carga?.direccionLugar?._errors[0]}
          fullWidth
          label="Dirección"
          size="small"
        />
        <TextField
          onChange={handleInput('carga')('fecha')}
          value={formValues.carga.fecha}
          helperText={errors?.carga?.fecha?._errors[0]}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputProps: {
              min: minDate,
            },
          }}
          type={'date'}
          label="Fecha Carga"
          size="small"
        />
        <TextField
          onChange={handleInput('carga')('hora')}
          value={formValues.carga.hora}
          helperText={errors?.carga?.hora?._errors[0]}
          fullWidth
          InputLabelProps={{ shrink: true }}
          type={'time'}
          label="Hora Carga"
          size="small"
        />
      </div>

      <div className="textContainer">
        <SubTitle3>Entrega del Contenedor</SubTitle3>
      </div>
      <div className="textFieldContainer">
        <TextField
          onChange={handleInput('entrega')('nombreLugar')}
          value={formValues.entrega.nombreLugar}
          helperText={errors?.entrega?.nombreLugar?._errors[0]}
          fullWidth
          label="Nombre Puerto"
          size="small"
        />
        <TextField
          onChange={handleInput('entrega')('direccionLugar')}
          value={formValues.entrega.direccionLugar}
          helperText={errors?.entrega?.direccionLugar?._errors[0]}
          fullWidth
          label="Dirección"
          size="small"
        />
        <TextField
          onChange={handleInput('entrega')('fecha')}
          value={formValues.entrega.fecha}
          helperText={errors?.entrega?.fecha?._errors[0]}
          fullWidth
          InputLabelProps={{ shrink: true }}
          InputProps={{
            inputProps: {
              min: minDate,
            },
          }}
          type={'date'}
          label="Fecha Entrega"
          size="small"
        />
        <TextField
          onChange={handleInput('entrega')('hora')}
          value={formValues.entrega.hora}
          helperText={errors?.entrega?.hora?._errors[0]}
          fullWidth
          InputLabelProps={{ shrink: true }}
          type={'time'}
          label="Hora Entrega"
          size="small"
        />
      </div>
    </>
  );
};

export default PuntoSalida;
