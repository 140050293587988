import { pipe } from 'fp-ts/function';
import { z } from 'zod';

const getRutParseado = (value: string): string => {
  if (value.length <= 0) {
    return '';
  }
  const rutK =
    value[value.length - 1].toString().toString().toUpperCase() === 'K';
  const rut = value.replace(/\D+/g, '');
  if (rutK) {
    return rut + 'K';
  }
  return rut;
};

const validateRut = (rut: string): boolean => {
  if (rut.length > 10 || rut.length < 7) return false;

  const value = rut;

  // Aislar Cuerpo y Dígito Verificador
  rut = value.slice(0, -1);
  let rutParseadoInt = parseInt(rut);
  let digv = value.slice(-1).toUpperCase();

  if (digv === 'K') digv = 'k';

  let M = 0,
    S = 1;
  for (; rutParseadoInt; rutParseadoInt = Math.floor(rutParseadoInt / 10))
    S = (S + (rutParseadoInt % 10) * (9 - (M++ % 6))) % 11;
  const result = S ? S - 1 : 'k';

  return result.toString() === digv;
};

const RutSchema = z
  .string()
  .refine((value) => pipe(value, getRutParseado, validateRut), {
    message: 'RUT Inválido',
  });

export default RutSchema;
