import { Observable, Observer } from 'rxjs';
import { useEffect } from 'react';

export const useObservable = <T>(
  observableFactory: () => Observable<T>,
  observer?: Partial<Observer<T>>
): void => {
  useEffect(() => {
    const subscription = observableFactory().subscribe(observer);

    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
