import { useRef } from 'react';

export const useFactory = <T>(factory: () => T): T => {
  const valueRef = useRef<T | null>(null);
  if (valueRef.current === null) {
    valueRef.current = factory();
  }

  return valueRef.current as T;
};
