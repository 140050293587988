import { locationsEnum, TLocations } from 'App';

export const palette = {
  colcom: {
    primary: {
      main: '#202D55',
      medium: '#506bbe',
      light: '#768BCC',
    },

    secondary: {
      main: '#e87879',
      light: '#f8d3d4',
    },

    error: {
      main: '#d3696b',
    },

    sidePanel: {
      bColor: '#e5e9f5',
      color: '#202D55',
      colorSelected: '#506bbe',
      circleSelected: '#506bbe',
      circleSelectedFont: '#f8d3d4',
      botonFontColor: 'unset',
      botonFontColorDisabled: 'unset',
      circleDoneCheck: '#768BCC',
    },
  },

  colsa: {
    primary: {
      main: '#202D55',
      medium: '#506bbe',
      light: '#768BCC',
    },

    secondary: {
      main: '#FFC166',
      light: '#FFC166',
    },

    error: {
      main: '#d3696b',
    },

    sidePanel: {
      bColor: '#243B70',
      color: 'white',
      colorSelected: 'white',
      circleSelected: '#DBE1F0',
      circleSelectedFont: '#243B70',
      botonFontColor: '#DBE1F0',
      botonFontColorDisabled: '#B8C4E0',
      circleDoneCheck: '#DBE1F0',
    },
  },
};

export const getThemeKey = (locale: TLocations): 'colcom' | 'colsa' =>
  locale === locationsEnum.enum.ar ? 'colsa' : 'colcom';
