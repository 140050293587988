import { css, cx } from '@emotion/css';
import { palette } from 'general/Styles';

const styles = {
  container: css`
    width: 100%;
    display: flex;
    align-items: center;
  `,
  Title: css`
    min-height: 15.09vh;
    & > span {
      display: block;
      font-size: 48px;
      font-family: Poppins, Medium, serif;
      line-height: 56px;
      color: ${palette.colcom.primary.main};
    }
  `,
  SubTitle: css`
    min-height: 9.44vh;
    & > span {
      display: block;
      font-size: 32px;
      font-family: Roboto, Medium, serif;
      line-height: 36px;
      color: ${palette.colcom.primary.main};
    }
  `,
  SubTitle2: css`
    min-height: 4.63vh;
    & > span {
      display: block;
      font-size: 16px;
      font-family: Roboto, Medium, serif;
      line-height: 20px;
      color: ${palette.colcom.primary.main};
    }
  `,
  SubTitle3: css`
    min-height: 6.2vh;
    & > span {
      display: block;
      font-size: 16px;
      font-family: Roboto, Medium;
      line-height: 20px;
      color: ${palette.colcom.primary.light};
    }
  `,
};

const Title: React.FC<{
  children: string;
  customStyles?: string;
}> = ({ children, customStyles = '' }) => {
  return (
    <div className={cx(styles.container, styles.Title, customStyles)}>
      <span>{children}</span>
    </div>
  );
};

const SubTitle: React.FC<{ children: string }> = ({ children }) => {
  return (
    <div className={cx(styles.container, styles.SubTitle)}>
      <span>{children}</span>
    </div>
  );
};

const SubTitle2: React.FC<{ children: string }> = ({ children }) => {
  return (
    <div className={cx(styles.container, styles.SubTitle2)}>
      <span>{children}</span>
    </div>
  );
};

const SubTitle3: React.FC<{ children: string }> = ({ children }) => {
  return (
    <div className={cx(styles.container, styles.SubTitle3)}>
      <span>{children}</span>
    </div>
  );
};

export { Title, SubTitle, SubTitle2, SubTitle3 };
