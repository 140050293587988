import { TForm } from 'form/Form';
import { useFactory } from 'services/useFactory';
import {
  catchError,
  delay,
  EMPTY,
  map,
  retry,
  Subject,
  switchMap,
  takeWhile,
  tap,
  throwError,
} from 'rxjs';
import { useState } from 'react';
import { useObservable } from 'services/useObservable';
import { fromFetch } from 'rxjs/fetch';
import { TLocations } from 'App';
import formToHtml from 'services/formToHtml';
import { default as config } from 'config';
import useLocale from 'services/useLocation';

const bodyFactory = (locale: TLocations): object => ({
  SenderId: 1,
  UserName: 'DEMO',
  Subject: `Nueva Solicitud para Demo ${locale}`,
  IsHtml: true,
  ...config.MAIL[locale],
});

type TMailPost = {
  form: TForm;
  token: string;
};

const useMail = (
  onError: () => void
): { loading: boolean; sendSource$: Subject<TMailPost> } => {
  const locale = useLocale();

  const typedLocale = locale as TLocations;

  const sendSource$ = useFactory(() => new Subject<TMailPost>());
  const [loading, setLoading] = useState(false);

  useObservable(
    () =>
      sendSource$.pipe(
        takeWhile(() => !loading),
        tap(() => setLoading(true)),
        map(({ token, form }) => ({
          html: formToHtml(form, typedLocale),
          token,
        })),
        switchMap(({ html, token }) =>
          fromFetch(config.MAIL_ENDPOINT, {
            method: 'POST',
            headers: new Headers({
              'Content-Type': 'application/json',
            }),
            body: JSON.stringify({
              ...bodyFactory(typedLocale),
              Body: html,
              Captcha: token,
            }),
          }).pipe(
            switchMap((response) => {
              if (response.ok) {
                return response.json();
              } else {
                return throwError(() => response.status);
              }
            }),
            delay(1000),
            retry({
              count: 3,
              delay: 1000,
            }),
            catchError(() => {
              setLoading(false);
              onError();
              return EMPTY;
            })
          )
        )
      ),
    {
      next: () => setLoading(false),
    }
  );

  return { loading, sendSource$ };
};

export default useMail;
