import { css } from '@emotion/css';
import { CopyAllOutlined, MapOutlined } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import { locationsEnum, TLocations } from 'App';
import { pipe } from 'fp-ts/function';
import MaskTextField from 'general/components/MaskTextField';
import {
  SubTitle,
  SubTitle2,
  SubTitle3,
} from 'general/components/Titles/Titles';
import { ContactoSchema } from 'general/schemas/ContactoSchema';
import CurpSchema from 'general/schemas/CurpSchema';
import RfcSchema from 'general/schemas/RfcSchema';
import RutSchema from 'general/schemas/RutSchema';
import { getThemeKey, palette } from 'general/Styles';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { debounceTime, raceWith, repeat, Subject, take } from 'rxjs';
import { useFactory } from 'services/useFactory';
import { useObservable } from 'services/useObservable';
import { match } from 'ts-pattern';
import { z } from 'zod';
import { filter, map } from 'fp-ts/Array';
import config from 'config';
import CuitCuilSchema from 'general/schemas/CuitCuilSchema';
import useLocale from 'services/useLocation';

interface IStyles {
  [key: string]: string;
}

const getStyles = (locale: TLocations): IStyles => {
  const theme = getThemeKey(locale);

  return {
    maps: css`
      display: flex;
      align-items: center;
      width: 100%;
      height: 4vh;
      & > span {
        color: ${palette[theme].secondary.main};
        font-size: 14px;
        font-family: Roboto, Regular, serif;
      }
    `,

    inputRuta: css`
      & input {
        color: -webkit-link;
        text-decoration: underline;
      }
    `,
  };
};

//#region schema
export const EmpresaSchema = ContactoSchema.extend({
  nombre: z
    .string()
    .trim()
    .min(1, { message: 'Debe estar completo' })
    .max(300, { message: 'Demasiado largo' }),

  email: z
    .string()
    .trim()
    .min(1, { message: 'Debe estar completo' })
    .max(300, { message: 'Demasiado largo' })
    .email({ message: 'Mail inválido' }),

  telefono: z
    .string()
    .trim()
    .min(1, { message: 'Debe estar completo' })
    .max(300, { message: 'Demasiado largo' })
    .regex(/^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/, {
      message: 'Inválido',
    }),

  rfc: RfcSchema.or(CurpSchema),

  rut: RutSchema,

  cuitCuil: CuitCuilSchema,

  direccion: z
    .string()
    .trim()
    .max(300, { message: 'Demasiado largo' })
    .or(z.literal(''))
    .optional(),

  ruta: z
    .string()
    .trim()
    .regex(
      /^(https:\/\/www\.google\..*\/maps\/dir\/.*|https:\/\/maps\.app\.goo\.gl\/.*)$/,
      {
        message:
          'Debe ser un link de Google Maps válido (https://www.google.../maps/dir/... o https://maps.app.goo.gl/...)',
      }
    )
    .or(z.literal(''))
    .optional(),
});

//#endregion
export type TEmpresa = z.infer<typeof EmpresaSchema>;

type TErrors = z.ZodFormattedError<TEmpresa> | null;

export const initialStateDatosIniciales: TEmpresa = {
  nombre: '',
  email: '',
  telefono: '',
  rfc: '',
  rut: '',
  cuitCuil: '',
  ruta: '',
  direccion: '',
};

type TLocaleFields = {
  [fields in keyof Partial<TEmpresa>]: TLocations[];
};

type TLocaleMaskSchema = {
  [fields in keyof TLocaleFields]: true | undefined;
};

const localeFields: TLocaleFields = {
  rut: ['cl'],
  rfc: ['mx'],
  cuitCuil: ['ar'],
};

const validateForm = (
  form: TEmpresa,
  setErrors: (errors: TErrors) => void,
  setValidForm: (valid: boolean) => void,
  locale: TLocations
): void => {
  // locale parse
  const maskLocaleFields = pipe(
    localeFields,
    Object.entries,
    filter(([_, value]) => !value.includes(locale)),
    map(([key, _]) => [key, true]),
    Object.fromEntries
  ) as TLocaleMaskSchema;

  const EmpresaLocaleSchema = EmpresaSchema.omit(maskLocaleFields);
  const result = EmpresaLocaleSchema.safeParse(form);

  if (!result.success) {
    // set errors
    setErrors(result.error.format());
    // set page invalid
    setValidForm(false);
  } else {
    // clear errors
    setErrors(null);
    // set page valid
    setValidForm(true);
  }
};

interface IDatosIniciales {
  handleValidStep: (valid: boolean) => void;
  handleFormValuePage: (newFormValues: TEmpresa) => void;
  getInitialState: () => TEmpresa;
}

const DatosIniciales: React.FC<IDatosIniciales> = ({
  handleValidStep,
  handleFormValuePage,
  getInitialState,
}) => {
  const locale = useLocale();

  const styles = useMemo(() => getStyles(locale), [locale]);

  const [formValues, setFormValues] = useState(getInitialState());

  const [errors, setErrors] = useState<TErrors>(null);

  const form$ = useFactory(() => new Subject<TEmpresa>());
  // para sincronizar los cambios cuando se abandona el componente sin esperar el delay
  const instantForm$ = useFactory(() => new Subject<TEmpresa>());

  // para guardar el último valor entre renderizados para el unmount
  const formValuesRef = useRef(formValues);
  useEffect(() => {
    formValuesRef.current = formValues;
  }, [formValues]);

  useEffect(() => {
    // unmount y actualizamos el estado
    return () => {
      instantForm$.next(formValuesRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useObservable(
    () =>
      form$.pipe(debounceTime(400), raceWith(instantForm$), take(1), repeat()),

    {
      next: (newFormValues) => {
        validateForm(newFormValues, setErrors, handleValidStep, locale);
        handleFormValuePage(newFormValues);
      },
    }
  );

  useEffect(
    () => validateForm(formValues, setErrors, handleValidStep, locale),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const handleInput =
    <T extends keyof TEmpresa>(
      field: T,
      ...modifiers: ((param: string) => string)[]
    ) =>
    (
      value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
    ) => {
      const inputValue = pipe(
        value,
        (stringOrEvent) => {
          if (typeof stringOrEvent === 'string') {
            return stringOrEvent;
          }
          return stringOrEvent.target.value;
        },
        ...(modifiers as [])
      );
      const newValues = { ...formValues, [field]: inputValue };
      form$.next(newValues);
      setFormValues(newValues);
    };

  const copyToRutaField = (): Promise<void> =>
    navigator.clipboard.readText().then((text) => {
      const newValues = { ...formValues, ruta: text };
      form$.next(newValues);
      setFormValues(newValues);
    });

  const handleOpenMaps = (): void => {
    window.open(config.GOOGLE_MAP_LINKS[locale], '_blank');
  };

  return (
    <>
      <div className="textContainer">
        <SubTitle>Datos Iniciales</SubTitle>
      </div>
      <div className="textContainer">
        <SubTitle2>
          Son los datos necesarios para vincular una empresa a nuestro sistema.
        </SubTitle2>
      </div>
      <div className="textContainer">
        <SubTitle3>Datos de la Empresa </SubTitle3>
      </div>
      <div className="textFieldContainer">
        <TextField
          onChange={handleInput('nombre')}
          value={formValues.nombre}
          helperText={errors?.nombre?._errors[0]}
          fullWidth
          label="Nombre Cliente"
          size="small"
        />
        {match<TLocations, ReactElement>(locale)
          .with(locationsEnum.enum.mx, () => (
            <TextField
              onChange={handleInput(
                'rfc',
                (s): string => s.toUpperCase(),
                (s): string => s.trim()
              )}
              value={formValues.rfc}
              helperText={errors?.rfc?._errors[0]}
              fullWidth
              label="RFC/CURP"
              size="small"
            />
          ))
          .with(locationsEnum.enum.cl, () => (
            <MaskTextField
              onAccept={handleInput('rut', (s): string => s.trim())}
              value={formValues.rut}
              helperText={errors?.rut?._errors[0]}
              fullWidth
              label="RUT"
              mask={[
                {
                  mask: '0.000.000-#',
                  definitions: {
                    '#': /[kK0-9]/,
                  },
                },
                {
                  mask: '00.000.000-#',
                  definitions: {
                    '#': /[kK0-9]/,
                  },
                },
              ]}
            />
          ))
          .with(locationsEnum.enum.ar, () => (
            <MaskTextField
              onAccept={handleInput('cuitCuil', (s): string => s.trim())}
              value={formValues.cuitCuil}
              helperText={errors?.cuitCuil?._errors[0]}
              fullWidth
              label="CUIT/CUIL"
              mask={'00-00000000-0'}
            />
          ))
          .exhaustive()}
        <TextField
          onChange={handleInput('email')}
          value={formValues.email}
          helperText={errors?.email?._errors[0]}
          fullWidth
          label="Mail Empresa"
          size="small"
        />
        <TextField
          onChange={handleInput('telefono')}
          value={formValues.telefono}
          helperText={errors?.telefono?._errors[0]}
          fullWidth
          label="Teléfono Empresa"
          size="small"
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          height: '6.48vh',
          padding: '10px 0',
        }}
      >
        <TextField
          onChange={handleInput('direccion')}
          value={formValues.direccion}
          helperText={errors?.direccion?._errors[0]}
          fullWidth
          label="Dirección"
          size="small"
        />
      </div>
      <div className="textContainer">
        <SubTitle3>Ruta</SubTitle3>
      </div>
      <TextField
        className={!Boolean(errors?.ruta) ? styles.inputRuta : undefined}
        onChange={handleInput('ruta')}
        value={formValues.ruta}
        helperText={errors?.ruta?._errors[0]}
        fullWidth
        size="small"
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleOpenMaps}>
              <MapOutlined htmlColor={palette.colcom.primary.medium} />
            </IconButton>
          ),
          startAdornment: (
            <IconButton onClick={copyToRutaField}>
              <CopyAllOutlined htmlColor={palette.colcom.primary.medium} />
            </IconButton>
          ),
        }}
      />
      <div className="textContainer">
        <div className={styles.maps}>
          <span>
            (Ingresa a{' '}
            <a
              href={config.GOOGLE_MAP_LINKS[locale]}
              target="_blank"
              rel="noopener noreferrer"
            >
              Maps
            </a>{' '}
            y establece la ruta a seguir, luego copia el link y pégalo aquí.)
          </span>
        </div>
      </div>
    </>
  );
};

export default DatosIniciales;
