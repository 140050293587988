import { TLocations } from 'App';

type TConfig = {
  PROD: boolean;
  MAIL: {
    [key in TLocations]: {
      To: [
        {
          DisplayName: string;
          Mail: string;
        }
      ];
    };
  };
  HOMEPAGE: {
    [key in TLocations]: string;
  };
  GOOGLE_MAP_LINKS: {
    [key in TLocations]: string;
  };
  CAPTCHA_KEY: string;
  MAIL_ENDPOINT: string;
};

const config: TConfig = {
  PROD: Boolean(process.env.REACT_APP_PROD),
  MAIL: {
    mx: {
      To: [
        {
          DisplayName: 'Soporte Mexico',
          Mail:
            process.env.REACT_APP_FORM_MX_MAIL ?? 'fabiantello@colsa.com.ar',
        },
      ],
    },
    cl: {
      To: [
        {
          DisplayName: 'Soporte Chile',
          Mail:
            process.env.REACT_APP_FORM_CL_MAIL ?? 'fabiantello@colsa.com.ar',
        },
      ],
    },
    ar: {
      To: [
        {
          DisplayName: 'Soporte Argentina',
          Mail:
            process.env.REACT_APP_FORM_AR_MAIL ?? 'fabiantello@colsa.com.ar',
        },
      ],
    },
  },
  HOMEPAGE: {
    mx: process.env.REACT_APP_HOMEPAGE_MX ?? 'https://www.colcomgps.mx/',
    cl: process.env.REACT_APP_HOMEPAGE_CL ?? 'https://www.colcomgps.cl/',
    ar: process.env.REACT_APP_HOMEPAGE_AR ?? 'https://www.colsa.com.ar/',
  },
  GOOGLE_MAP_LINKS: {
    mx:
      process.env.REACT_APP_GLINK_MX ??
      'https://www.google.com.ar/maps/dir///@20.5645519,-102.6185013,5.5z/data=!4m2!4m1!3e0',
    cl:
      process.env.REACT_APP_GLINK_CL ??
      'https://www.google.com.ar/maps/dir///@-33.4359174,-71.0595743,9.75z/data=!4m2!4m1!3e0',
    ar:
      process.env.REACT_APP_GLINK_AR ??
      'https://www.google.com.ar/maps/dir///@-39.7086691,-72.6221296,5z/data=!4m2!4m1!3e0',
  },

  CAPTCHA_KEY: process.env.REACT_APP_CAPTCHA_KEY ?? '',

  MAIL_ENDPOINT:
    process.env.REACT_APP_MAIL_ENDPOINT ??
    'https://email.linkcargas.com.ar/api/common/Mail/v1/Save',
};

export default config;
