import { TextField, TextFieldProps } from '@mui/material';
import { ComponentProps } from 'react';
import { IMaskMixin } from 'react-imask';

const InternalMaskTextField = IMaskMixin((props) => (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  <TextField size="small" {...(props as any)} />
));

type MaskProps = ComponentProps<typeof InternalMaskTextField>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const MaskTextField = (props: TextFieldProps & MaskProps) => {
  return <InternalMaskTextField {...props} />;
};

export default MaskTextField;
