import dayjs from 'dayjs';
import { z } from 'zod';

const IsoDateSchema = z
  .string()
  .transform((s) => dayjs(s)) // Transforma la cadena en un objeto Day.js
  .superRefine((val, ctx) => {
    if (!val.isValid()) {
      // Verifica si la fecha es válida
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Fecha inválida',
        fatal: true,
      });
    }
  })
  .superRefine((val, ctx) => {
    if (dayjs().isAfter(val)) {
      // Verifica si la fecha es del pasado
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'La fecha debe ser del futuro',
        fatal: true,
      });
    }
  })
  .transform((d) => d.toISOString()); // Convierte el objeto Day.js en una cadena ISO

export default IsoDateSchema;
