import { css, cx } from '@emotion/css';
import { Button } from '@mui/material';
import { Title } from 'general/components/Titles/Titles';
import { getThemeKey, palette } from 'general/Styles';
import { TLocations } from 'App';
import { useMemo } from 'react';
import useLocale from 'services/useLocation';

interface IStyles {
  [key: string]: string;
}

const getStyles = (locale: TLocations): IStyles => {
  const theme = getThemeKey(locale);

  return {
    container: css`
      display: grid;
      place-items: center;

      & #box {
        padding: 20px;
        height: 488px;
        width: 614px;
        display: grid;
        grid-template-rows: 123px 1px 292px 67px;
        box-shadow: 0 0 15px #00000026;
        border-radius: 15px;

        & div {
          padding: 10px;
        }
      }

      & #line {
        background-color: ${palette[theme].primary.light};
        padding: 0 !important;
      }

      & .textBox {
        display: grid;

        span {
          padding-top: 10px;
          color: ${palette[theme].primary.medium};
          /* margin: 20px 0; */
          font-size: 18px;
          font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
            Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        }
      }

      & .buttonContainer {
        display: flex;
        flex-direction: row-reverse;
        align-items: center;

        & .MuiButton-root {
          text-transform: none;
          background-color: ${palette[theme].primary.medium};
          color: ${palette[theme].secondary.light};
          padding: 0 64px;
          height: 35px;
        }
      }
    `,
    title: css`
      & > span {
        color: ${palette[theme].primary.medium};
      }
    `,
  };
};

interface IBienvenida {
  handleComenzar: () => void;
}

const Bienvenida: React.FC<IBienvenida> = ({ handleComenzar }) => {
  const locale = useLocale();

  const styles = useMemo(() => getStyles(locale), [locale]);
  return (
    <div className={cx('mensaje', styles.container)}>
      <div id="box">
        <Title customStyles={styles.title}>Bienvenido!</Title>
        <div id="line" />
        <div className="textBox">
          <span>
            + A continuación vamos a solicitar los datos necesarios para
            realizar una demo.
          </span>

          <span>+Este proceso no te va a tomar mucho tiempo.</span>
          <span>
            + Cada dato es importante y cumple una función en el sistema.
          </span>

          <span>
            + Ante cualquier consulta no dude en comunicarse con Nosotros.
          </span>
        </div>

        <div className="buttonContainer">
          <Button onClick={handleComenzar}>Comenzar</Button>
        </div>
      </div>
    </div>
  );
};

export default Bienvenida;
