type TLocales = 'mx' | 'cl' | 'ar';

const useLocale = (): TLocales => {
  const { hostname } = document.location;

  if (hostname.includes('cl')) return 'cl';

  if (hostname.includes('ar')) return 'ar';

  return 'mx';
};

export default useLocale;
