import { css } from '@emotion/css';
import { PersonOutline } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { locationsEnum, TLocations } from 'App';
import {
  SubTitle,
  SubTitle2,
  SubTitle3,
} from 'general/components/Titles/Titles';
import { ContactoSchema, TContacto } from 'general/schemas/ContactoSchema';
import { getThemeKey, palette } from 'general/Styles';
import { ReactElement, useEffect, useMemo, useRef, useState } from 'react';
import { debounceTime, raceWith, repeat, Subject, take } from 'rxjs';
import { useFactory } from 'services/useFactory';
import { useObservable } from 'services/useObservable';
import { match } from 'ts-pattern';
import { z } from 'zod';
import { pipe } from 'fp-ts/function';
import MaskTextField from 'general/components/MaskTextField';
import useLocale from 'services/useLocation';

interface IStyles {
  [key: string]: string;
}

const getStyles = (locale: TLocations): IStyles => {
  const theme = getThemeKey(locale);

  return {
    circlePerson: css`
      background-color: ${palette[theme].primary.medium};
      border-radius: 50%;
      display: grid;
      place-items: center;
      color: #f8d3d4;
      width: 2.35vw;
      aspect-ratio: 1 / 1;
      z-index: 1;
    `,
  };
};

//#region schema
export const RespSeguridadSchema = z.object({
  contacto1: ContactoSchema,
  contacto2: ContactoSchema,
});

//#endregion
type TRespSeguridad = z.infer<typeof RespSeguridadSchema>;

type TErrors = z.ZodFormattedError<TRespSeguridad> | null;

const initialStateContacto: TContacto = {
  nombre: '',
  email: '',
  telefono: '',
  rfc: '',
  rut: '',
};

export const initialStateRespSeguridad: TRespSeguridad = {
  contacto1: initialStateContacto,
  contacto2: initialStateContacto,
};

const validateForm = (
  form: TRespSeguridad,
  setErrors: (errors: TErrors) => void,
  setValidForm: (valid: boolean) => void
): void => {
  const result = RespSeguridadSchema.safeParse(form);
  if (!result.success) {
    // set errors
    setErrors(result.error.format());
    // set page invalid
    setValidForm(false);
  } else {
    // clear errors
    setErrors(null);
    // set page valid
    setValidForm(true);
  }
};

interface IRespSeguridad {
  handleValidStep: (valid: boolean) => void;
  handleFormValuePage: (newFormValues: TRespSeguridad) => void;
  getInitialState: () => TRespSeguridad;
}

const RespSeguridad: React.FC<IRespSeguridad> = ({
  handleValidStep,
  handleFormValuePage,
  getInitialState,
}) => {
  const locale = useLocale();

  const styles = useMemo(() => getStyles(locale), [locale]);

  const [formValues, setFormValues] = useState(getInitialState());

  const [errors, setErrors] = useState<TErrors>(null);

  const form$ = useFactory(() => new Subject<TRespSeguridad>());
  // para sincronizar los cambios cuando se abandona el componente sin esperar el delay
  const instantForm$ = useFactory(() => new Subject<TRespSeguridad>());

  // para guardar el último valor entre renderizados para el unmount
  const formValuesRef = useRef(formValues);
  useEffect(() => {
    formValuesRef.current = formValues;
  }, [formValues]);

  useEffect(() => {
    // unmount y actualizamos el estado
    return () => {
      instantForm$.next(formValuesRef.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useObservable(
    () =>
      form$.pipe(debounceTime(400), raceWith(instantForm$), take(1), repeat()),

    {
      next: (newFormValues) => {
        validateForm(newFormValues, setErrors, handleValidStep);
        handleFormValuePage(newFormValues);
      },
    }
  );

  const handleInput =
    <Ta extends keyof TRespSeguridad>(contacto: Ta) =>
    <Tb extends keyof TRespSeguridad[typeof contacto]>(
      field: Tb,
      ...modifiers: ((param: string) => string)[]
    ) =>
    (
      value: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | string
    ) => {
      const inputValue = pipe(
        value,
        (stringOrEvent) => {
          if (typeof stringOrEvent === 'string') {
            return stringOrEvent;
          }
          return stringOrEvent.target.value;
        },

        ...(modifiers as [])
      );
      const newValues = {
        ...formValues,
        [contacto]: { ...formValues[contacto], [field]: inputValue },
      };
      form$.next(newValues);
      setFormValues(newValues);
    };

  return (
    <>
      <div
        style={{
          gridRow: '4',
          gridColumn: '2',
          justifySelf: 'center',
        }}
        className={styles.circlePerson}
      >
        <PersonOutline />
      </div>
      <div
        style={{
          gridRow: '6',
          gridColumn: '2',
          justifySelf: 'center',
        }}
        className={styles.circlePerson}
      >
        <PersonOutline />
      </div>
      <div className="textContainer">
        <SubTitle>Responsable de Seguridad del Viaje</SubTitle>
      </div>
      <div className="textContainer">
        <SubTitle2>
          El Responsable de seguridad es la personas a la cual se le van a
          informar los eventos del viaje. En nuestra experiencia recomendamos
          completar con 2(dos) personas ya que estos eventos pueden ocurrir en
          diversos días y horarios.
        </SubTitle2>
      </div>
      <div className="textContainer">
        <SubTitle3>Contacto 1</SubTitle3>
      </div>
      <div className="textFieldContainer">
        <TextField
          onChange={handleInput('contacto1')('nombre')}
          value={formValues.contacto1.nombre}
          helperText={errors?.contacto1?.nombre?._errors[0]}
          fullWidth
          label="Nombre"
          size="small"
        />
        {match<TLocations, ReactElement>(locale)
          .with(locationsEnum.enum.mx, () => (
            <TextField
              onChange={handleInput('contacto1')(
                'rfc',
                (s): string => s.toUpperCase(),
                (s): string => s.trim()
              )}
              value={formValues.contacto1.rfc}
              helperText={errors?.contacto1?._errors[0]}
              fullWidth
              label="RFC/CURP"
              size="small"
            />
          ))
          .with(locationsEnum.enum.cl, () => (
            <MaskTextField
              onAccept={handleInput('contacto1')('rut', (s): string =>
                s.trim()
              )}
              value={formValues.contacto1.rut}
              helperText={errors?.contacto1?.rut?._errors[0]}
              fullWidth
              label="RUT"
              mask={[
                {
                  mask: '0.000.000-#',
                  definitions: {
                    '#': /[kK0-9]/,
                  },
                },
                {
                  mask: '00.000.000-#',
                  definitions: {
                    '#': /[kK0-9]/,
                  },
                },
              ]}
            />
          ))
          .with(locationsEnum.enum.ar, () => (
            <MaskTextField
              onAccept={handleInput('contacto1')('cuitCuil', (s): string =>
                s.trim()
              )}
              value={formValues.contacto1.cuitCuil}
              helperText={errors?.contacto1?.cuitCuil?._errors[0]}
              fullWidth
              label="CUIT/CUIL"
              mask={'00-00000000-0'}
            />
          ))
          .exhaustive()}
        <TextField
          onChange={handleInput('contacto1')('telefono')}
          value={formValues.contacto1.telefono}
          helperText={errors?.contacto1?.telefono?._errors[0]}
          fullWidth
          label="Teléfono"
          size="small"
        />
        <TextField
          onChange={handleInput('contacto1')('email')}
          value={formValues.contacto1.email}
          helperText={errors?.contacto1?.email?._errors[0]}
          fullWidth
          label="Mail"
          size="small"
        />
      </div>
      <div className="textContainer">
        <SubTitle3>Contacto 2</SubTitle3>
      </div>
      <div className="textFieldContainer">
        <TextField
          onChange={handleInput('contacto2')('nombre')}
          value={formValues.contacto2.nombre}
          helperText={errors?.contacto2?.nombre?._errors[0]}
          fullWidth
          label="Nombre"
          size="small"
        />
        {match<TLocations, ReactElement>(locale)
          .with(locationsEnum.enum.mx, () => (
            <TextField
              onChange={handleInput('contacto2')(
                'rfc',
                (s): string => s.toUpperCase(),
                (s): string => s.trim()
              )}
              value={formValues.contacto2.rfc}
              helperText={errors?.contacto2?._errors[0]}
              fullWidth
              label="RFC/CURP"
              size="small"
            />
          ))
          .with(locationsEnum.enum.cl, () => (
            <MaskTextField
              onAccept={handleInput('contacto2')('rut', (s): string =>
                s.trim()
              )}
              value={formValues.contacto2.rut}
              helperText={errors?.contacto2?.rut?._errors[0]}
              fullWidth
              label="RUT"
              mask={[
                {
                  mask: '0.000.000-#',
                  definitions: {
                    '#': /[kK0-9]/,
                  },
                },
                {
                  mask: '00.000.000-#',
                  definitions: {
                    '#': /[kK0-9]/,
                  },
                },
              ]}
            />
          ))
          .with(locationsEnum.enum.ar, () => (
            <MaskTextField
              onAccept={handleInput('contacto2')('cuitCuil', (s): string =>
                s.trim()
              )}
              value={formValues.contacto2.cuitCuil}
              helperText={errors?.contacto2?.cuitCuil?._errors[0]}
              fullWidth
              label="CUIT/CUIL"
              mask={'00-00000000-0'}
            />
          ))
          .exhaustive()}
        <TextField
          onChange={handleInput('contacto2')('telefono')}
          value={formValues.contacto2.telefono}
          helperText={errors?.contacto2?.telefono?._errors[0]}
          fullWidth
          label="Teléfono"
          size="small"
        />
        <TextField
          onChange={handleInput('contacto2')('email')}
          value={formValues.contacto2.email}
          helperText={errors?.contacto2?.email?._errors[0]}
          fullWidth
          label="Mail"
          size="small"
        />
      </div>
    </>
  );
};

export default RespSeguridad;
