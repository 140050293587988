import { z } from 'zod';

const validateCuit = (cuit: string): boolean => {
  const cuitParseado = cuit.replace(/\D+/g, '');
  if (cuitParseado.length !== 11) return false;

  const tiposCuit = ['20', '23', '24', '27', '30', '33', '34'];

  if (tiposCuit.indexOf(cuitParseado.substring(0, 2)) === -1) return false;

  const mult = '6789456789';
  const aMult = mult.split('');
  let iResult = 0;
  const aCUIT = cuitParseado.split('');

  // La suma de los productos
  for (let i = 0; i <= 9; i++) {
    iResult += parseInt(aCUIT[i]) * parseInt(aMult[i]);
  }
  // El módulo de 11
  iResult = iResult % 11;

  return cuitParseado[10] === iResult.toString();
};

const CuitCuilSchema = z.string().refine(validateCuit, {
  message: 'CUIT/CUIL inválido',
});

export default CuitCuilSchema;
