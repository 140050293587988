import { TForm } from 'form/Form';
import { palette } from 'general/Styles';
import * as ReactDOMServer from 'react-dom/server';
import { locationsEnum, TLocations } from 'App';
import { match } from 'ts-pattern';

const omitDeep = (obj: any, omitKeys: string[]): any => {
  if (Array.isArray(obj)) {
    return obj.map((v) => omitDeep(v, omitKeys));
  } else if (obj != null && obj.constructor === Object) {
    for (const key in obj) {
      const value = obj[key];
      if (value != null && value.constructor === Object) {
        omitDeep(value, omitKeys);
      } else if (omitKeys.includes(key)) {
        delete obj[key];
      }
    }
  }
  return obj;
};

const objToTable = (obj: object, title: string): JSX.Element => (
  <table
    style={{
      tableLayout: 'fixed',
      overflowWrap: 'break-word',
      border: `1px solid ${palette.colcom.primary.light}`,
      borderCollapse: 'collapse',
    }}
  >
    <tbody>
      <tr>
        <th
          style={{
            border: `1px solid ${palette.colcom.primary.light}`,
            borderCollapse: 'collapse',
            padding: '0 10px',
          }}
        >
          {title}
        </th>
      </tr>
      {Object.entries(obj).map(([key, value], index) => (
        <tr key={index}>
          <td
            style={{
              border: `1px solid ${palette.colcom.primary.light}`,
              borderCollapse: 'collapse',
              padding: '0 10px',
            }}
          >
            {key}
          </td>
          <td
            style={{
              border: `1px solid ${palette.colcom.primary.light}`,
              borderCollapse: 'collapse',
              padding: '0 10px',
            }}
          >
            {(value as string).length > 0 ? value : 'vacío'}
          </td>
        </tr>
      ))}
    </tbody>
  </table>
);

type FlattenObjectKeys<
  T extends Record<string, unknown>,
  Key = keyof T
> = Key extends string
  ? T[Key] extends Record<string, unknown>
    ? `${FlattenObjectKeys<T[Key]>}`
    : `${Key}`
  : never;

type TLocaleFields = {
  [keys in FlattenObjectKeys<TForm>]?: TLocations[];
};

const formToHtml = (form: TForm, locale: TLocations): string => {
  const localeFields: TLocaleFields = {
    rut: ['cl'],
    rfc: ['mx'],
  };

  const excludefields = Object.entries(localeFields)
    .filter(([_, locales]) => !locales.includes(locale))
    .map(([key, _]) => key);

  const formToPrint = omitDeep(form, excludefields);

  return ReactDOMServer.renderToStaticMarkup(
    <div>
      <h2>Nueva solicitud demo</h2>
      <h2>
        País: del solicitante:{' '}
        {match(locale)
          .with(locationsEnum.enum.mx, () => 'México')
          .with(locationsEnum.enum.cl, () => 'Chile')
          .with(locationsEnum.enum.ar, () => 'Argentina')
          .exhaustive()}
      </h2>

      <h3>Datos de contacto de la empresa</h3>
      {objToTable(formToPrint.datosIniciales, 'Empresa')}
      <div
        style={{
          height: '20px',
          width: '100%',
        }}
      />
      <h3>Puntos de Salida / Carga / Entrega</h3>
      {objToTable(formToPrint.puntosSalidas.carga, 'Carga')}
      {objToTable(formToPrint.puntosSalidas.entrega, 'Entrega')}
      {objToTable(formToPrint.puntosSalidas.salida, 'Salida')}
      <div
        style={{
          height: '20px',
          width: '100%',
        }}
      />
      <h3>Responsables Seguridad</h3>
      {objToTable(formToPrint.respSeguridad.contacto1, 'Contacto1')}
      {objToTable(formToPrint.respSeguridad.contacto2, 'Contacto2')}
      <div
        style={{
          height: '20px',
          width: '100%',
        }}
      />
      <h3>Datos Transportista</h3>
      {objToTable(formToPrint.transportista, 'transportista')}
    </div>
  );
};

export default formToHtml;
