import Form from 'form/Form';
import { BrowserRouter, Navigate, useRoutes } from 'react-router-dom';
import { z } from 'zod';
import { Toaster } from 'react-hot-toast';

const LOCATIONS = ['mx', 'cl', 'ar'] as const;

export const locationsEnum = z.enum(LOCATIONS);

export type TLocations = z.infer<typeof locationsEnum>;

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const Routes = () =>
  useRoutes([
    { path: '/', element: <Form /> },
    { path: '*', element: <Navigate to={'/'} /> },
  ]);

const App: React.FC = () => {
  return (
    <>
      <BrowserRouter>
        <Routes />
      </BrowserRouter>
      <Toaster position={'bottom-center'} />
    </>
  );
};

export default App;
